import React from "react";

const SumComponent = ({ data }) => {
  let totalQuantity = 0;
  let totalPrice = 0;
  data.forEach((item) => {
    totalQuantity += item.quantity;
    totalPrice += parseInt(item.price);
  });

  return (
    <div>
      Quantity: {totalQuantity} For Rs.{totalPrice}
    </div>
  );
};

export default SumComponent;
