import { useContext } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { AuthContext } from "./authContext/AuthContext";
import PrivateRoutes from "./PrivateRoutes";
import ProductsTable from "./pages/Products";
import ProductDetails from "./pages/ProductDetails";
import Dashboard from "./pages/Dashboard";
import OrdersTable from "./pages/ordersTable";
import OrderDetail from "./pages/orderDetails";
import ReturnOrders from "./pages/ReturnOrders";
import ReturnOrderDetails from "./pages/ReturnOrderDetails";
import ReturnProducts from "./pages/ReturnProducts";
import ReturnProductDetails from "./pages/ReturnProductDetails";
import SaleDetails from "./pages/admin/SaleDetails";
import AdminProducts from "./pages/admin/AdminProducts";
import AdminProductDetails from "./pages/admin/AdminProductDetails";
import InventoryForm from "./pages/admin/InventoryForm";
import RegisterPage from "./pages/RegPage";
import Users from "./pages/admin/Users";
import Stores from "./pages/admin/SourceStores";
import StoresDetails from "./pages/admin/StoreDetail";
import LoginPage from "./pages/LoginPage";

//import "./App.css";

function App() {
  const { user } = useContext(AuthContext);
  const userToken = user?.accessToken;

  const handleLogout = () => {
    localStorage.removeItem("user");
    return <Navigate to="/login" />;
  };
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route path="/register" element={<RegisterPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Dashboard onLogout={handleLogout} />}>
            <Route
              path="/orderproducts"
              element={<ProductsTable authToken={userToken} />}
            />
            <Route
              path="/productdetails/:product_id"
              element={<ProductDetails authToken={userToken} />}
            />
            <Route
              path="/returnproducts"
              element={<ReturnProducts authToken={userToken} />}
            />
            <Route
              path="/returnproductdetails/:product_id"
              element={<ReturnProductDetails authToken={userToken} />}
            />
            <Route
              path="/orders"
              element={<OrdersTable authToken={userToken} />}
            />
            <Route
              path="/orderdetails/:orderId"
              element={<OrderDetail authToken={userToken} />}
            />

            <Route
              path="/returnorders"
              element={<ReturnOrders authToken={userToken} />}
            />
            <Route
              path="/returnorderdetails/:orderId"
              element={<ReturnOrderDetails authToken={userToken} />}
            />
            <Route
              path="/saledetails/:storeId"
              element={<SaleDetails authToken={userToken} />}
            />
            <Route
              path="/adminproducts"
              element={<AdminProducts authToken={userToken} />}
            />
            <Route
              path="/adminproductdetails/:product_id"
              element={<AdminProductDetails authToken={userToken} />}
            />
            <Route
              path="/inventoryForm/:variantId"
              element={<InventoryForm authToken={userToken} />}
            />
            <Route path="/users" element={<Users authToken={userToken} />} />
            <Route path="/stores" element={<Stores authToken={userToken} />} />
            <Route
              path="/storesdetails/:id"
              element={<StoresDetails authToken={userToken} />}
            />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
