// DataTable.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"; // Updated import
import SaveIcon from "@mui/icons-material/Save";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const ReturnOrdersTable = ({ authToken }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await api.get(`returnorders/all`, {
        headers: {
          token: authToken,
        },
      });

      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveClick = async (order_id) => {
    const confirmed = window.confirm(
      "Are you sure you want to Submit this order And pay?"
    );
    if (confirmed) {
      try {
        const response = await api.put(
          "returnorders/save",
          { order_id: order_id },
          {
            headers: {
              token: authToken,
            },
          }
        );
        setSnackbar({
          children: response.data,
          severity: "success",
        });
        fetchData();
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
  };

  const submitOrderPrint = async (idValue) => {
    const confirmed = window.confirm(
      "Are you sure you want to Print this order?"
    );
    if (confirmed) {
      try {
        const response = await api.get(`returnorders/print/${idValue}`, {
          responseType: "blob", // Set the response type to blob
          headers: {
            token: authToken,
          },
        });
        setFile(response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "return_order_details.pdf"); // Set the file name and extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
  };
  const handleDownload = () => {
    if (file) {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "return_order_details.pdf"); // Set the file name and extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      field: "order_id",
      headerName: "Order",
      width: 150,
    },

    {
      field: "status",
      headerName: "Status",
      width: 180,
    },
    { field: "total_price", headerName: "Total", width: 200 },
    { field: "total_qty", headerName: "Qty", width: 100 },
    { field: "createdAt", headerName: "Date", width: 120 },
    {
      field: "isPaid",
      headerName: "Is paid?",
      type: "boolean",
      width: 140,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      width: 100,
      getActions: (params) => {
        const isPaid = params.row.isPaid;

        const actions = [];

        if (!isPaid) {
          actions.push(
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={() => handleSaveClick(params.id)}
            />
          );
        }

        if (isPaid) {
          actions.push(
            <GridActionsCellItem
              icon={<FileCopyIcon />}
              label="Submit"
              onClick={() => submitOrderPrint(params.id)}
            />
          );
        }

        return actions;
      },
    },
  ];

  const handleRowClick = (params) => {
    const { row } = params;
    const { order_id } = row;

    navigate(`/returnorderdetails/${order_id}`);
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h5>Return Orders</h5>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div>
        {file && (
          <>
            <button className="btn btn-success" onClick={handleDownload}>
              Download File
            </button>
          </>
        )}
      </div>
      <div style={{ height: 800, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.order_id}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

export default ReturnOrdersTable;
