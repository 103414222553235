import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams hook
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"; // Updated import
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const OrderDetail = ({ authToken }) => {
  const { orderId } = useParams(); // Get the product_id from URL parameter
  const [orderData, setOrderData] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [loading, setLoading] = useState(true);

  const getTheOrder = async () => {
    setLoading(true);
    try {
      const response = await api.get(`orders/find/${orderId}`, {
        headers: { token: authToken },
      });
      setOrderData(response.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching order data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getTheOrder();
  }, [orderId]);

  const deleteOrderProduct = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Product?"
    );

    try {
      if (confirmed) {
        const response = await api.delete(`orders/item/${id}`, {
          headers: { token: authToken },
        });

        setSnackbar({
          children: response.data,
          severity: "success",
        });
        getTheOrder();
      } else {
        setSnackbar({
          children: "You Did not confirm to delet Not Deleted ",
          severity: "warning",
        });
      }
    } catch (error) {
      console.error("Error delet order data:", error);

      setSnackbar({
        children: error.message,
        severity: "error",
      });
    }
  };
  const viewProductD = async (id) => {
    alert(id);
  };
  const columns = [
    { field: "order_id", headerName: "OID", width: 100 },
    { field: "item_name", headerName: "Product", width: 450 },
    { field: "product_id", headerName: "Product ID", width: 200 },
    { field: "quantity", headerName: "Quantity", width: 150 },
    { field: "variant_title", headerName: "Size", width: 100 },
    { field: "price", headerName: "Price", width: 150 },
    { field: "createdAt", headerName: "Time", width: 200 },

    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      width: 100,
      getActions: (params) => {
        const actions = [];

        if (!orderData.isPaid) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => deleteOrderProduct(params.row.id)}
            />
          );
        }

        if (orderData.isPaid) {
          actions.push(
            <GridActionsCellItem
              icon={<FileCopyIcon />}
              label="View"
              onClick={() => viewProductD(params.row.variantId)}
            />
          );
        }

        return actions;
      },
    },
  ];
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Snackbar
        open={snackbar ? true : false}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert {...snackbar} onClose={handleCloseSnackbar} />
      </Snackbar>

      <div className="d-flex shadow p-3 mb-5 bg-body rounded  justify-content-between">
        <p>Order Number: {orderId}</p>
        <p>Total Products: {orderData.total_qty}</p>
        <p>Total Price : {orderData.total_price}</p>
        <p className="">Status : {orderData.status}</p>
      </div>

      <div style={{ height: 800, width: "100%" }}>
        <DataGrid
          rowHeight={25}
          rows={orderData.OrderDetails}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default OrderDetail;
