import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid"; // Updated import
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const ProductsTable = ({ authToken }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get("products/active", {
          headers: {
            token: authToken,
          },
        });
        setProducts(response.data);
        setLoading(false);
        setSnackbar({
          children: location.state?.message || "Products Are Loaded",
          severity: "success",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [location]);

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_image",
      headerName: "Image",
      width: 70,
      renderCell: (params) => (
        <img src={params.value} style={{ width: 50, height: 50 }} />
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_title",
      headerName: "Title",
      width: 500,
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_status",
      headerName: "Status",
      width: 150,
    },
  ];

  const handleRowClick = (params) => {
    const { row } = params;
    const { product_id } = row;

    navigate(`/productdetails/${product_id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h3 className="shadow p-3 mb-2 bg-body rounded">
        {" "}
        Add Products to your order
      </h3>
      <div className="d-flex justify-content-center shadow p-3 mb-5 bg-body rounded">
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        <div className="" style={{ height: "80vh", overflowX: "auto" }}>
          <DataGrid
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .super-app-theme--header": {
                backgroundColor: "rgba(42, 255, 0, 0.5)",
              },
            }}
            rows={products}
            columns={columns}
            pageSize={5}
            getRowId={(row) => row.product_id}
            onRowClick={handleRowClick}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
