import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams hook
import { DataGrid } from "@mui/x-data-grid"; // Updated import

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const SaleDetails = ({ authToken }) => {
  const { storeId } = useParams(); // Get the product_id from URL parameter
  const [data, setData] = useState(null);
  const [shopifyData, setShopifyData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getTheOrder = async () => {
    setLoading(true);
    try {
      const response = await api.get(`orders/invhistory/${storeId}`, {
        headers: { token: authToken },
      });
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching order data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getTheOrder();
  }, [storeId]);
  async function viewShopifyData() {
    setLoading(true);
    try {
      const response = await api.get(`orders/invhistory/shopify/${storeId}`, {
        headers: { token: authToken },
      });
      setShopifyData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching order data:", error);
      setLoading(false);
    }
  }

  const columns = [
    { field: "order_id", headerName: "OID", width: 100 },
    { field: "item_name", headerName: "Product", width: 450 },
    { field: "variant_title", headerName: "Size", width: 100 },
    { field: "product_id", headerName: "Product ID", width: 200 },
    { field: "variant_id", headerName: "Variant ID", width: 200 },
    { field: "quantity", headerName: "Quantity", width: 100 },

    { field: "type", headerName: "Type", width: 100 },
    { field: "createdAt", headerName: "Time", width: 200 },
  ];
  const columns2 = [
    { field: "store_name", headerName: "Shop", width: 100 },
    { field: "item_name", headerName: "Product", width: 450 },
    { field: "variant_title", headerName: "Size", width: 100 },
    { field: "product_id", headerName: "Product ID", width: 200 },
    { field: "variant_id", headerName: "Variant ID", width: 200 },

    { field: "quantity", headerName: "Quantity", width: 150 },

    { field: "createdAt", headerName: "Time", width: 200 },
  ];
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary float-end"
        onClick={viewShopifyData}
      >
        <i className="bi bi-gear"></i> &nbsp; View Shopify Data
      </button>
      <br />
      <br />
      {shopifyData ? (
        <div style={{ height: 800, width: "100%" }}>
          <h4>shopify data</h4>
          <DataGrid
            rowHeight={25}
            rows={shopifyData}
            columns={columns2}
            pageSize={5}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
          />
        </div>
      ) : (
        <div style={{ height: 800, width: "100%" }}>
          <h4>orders history</h4>
          <DataGrid
            rowHeight={25}
            rows={data}
            columns={columns}
            pageSize={5}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
          />
        </div>
      )}
    </div>
  );
};

export default SaleDetails;
