import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Audio } from "react-loader-spinner";
import "../model.css";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const ProductDetails = ({ authToken }) => {
  const { product_id } = useParams();
  const [productData, setProductData] = useState([]);
  const [variantsData, setVariantsData] = useState([]);
  const [variantFiltr, setVariantFilter] = useState([]);
  const [newQuantity, setNewQuantity] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`product/${product_id}`, {
          headers: {
            Authorization: `${authToken}`,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          setProductData(response.data.product);
          setVariantsData(response.data.variants);
          setLoading(false);
        } else {
          setError(`Request failed with status ${response.data}`);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        setError("Error fetching Data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [product_id, authToken]);
  const handleSubmit = () => {
    togglePopup();
    console.log(snackbar);
    setSnackbar({
      children: "Submit Clicked",
      severity: "success",
    });
  };

  const handleSizeClick = (variantId) => {
    setVariantFilter(
      variantsData.find((variant) => variant.variant_id === variantId)
    );
    togglePopup();
  };
  if (loading) {
    return (
      <div className="container">
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      </div>
    );
  }
  if (error) {
    return (
      <div className="container">
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        <p className="alert alert-danger">
          <Audio
            height="50"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
          There is An Error: {error}
        </p>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="col-lg-4 col-xl-4 col-sm-6">
        <div className="card">
          <img
            src={productData[0].product_image}
            className="card-img-top"
            alt="Product"
            height="450"
          />
          <div className="card-body">
            <div className="container">
              <p className="alert alert-primary row">
                {productData[0].product_title}
              </p>

              <div className="row ">
                <p
                  className={`btn ${
                    productData[0].product_status === "draft"
                      ? "btn-danger"
                      : "btn-success"
                  }`}
                >
                  Status: {productData[0].product_status}
                </p>
              </div>
            </div>

            {variantsData.map((variant, index) => (
              <div
                className="btn btn-outline-secondary m-1"
                key={variant.variantsTable_id}
                onClick={() => handleSizeClick(variant.variant_id)}
              >
                <p className="">{variant.variant_title}</p>
                <hr />
                <p className="">{variant.inventory_quantity}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="">
          <div className="modals">
            <div className="modal-contents">
              <div className="modal-header">
                <h6 className="modal-title">Enter Quantity </h6>
                <span onClick={togglePopup} className="close-btn">
                  &times;
                </span>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <input
                    placeholder="Enter Quantity"
                    type="number"
                    className="form-control m-1"
                    name="Pin"
                    value={newQuantity}
                    onChange={(e) => setNewQuantity(e.target.value)}
                  />
                </div>
              </div>

              {variantFiltr ? (
                <div>
                  <p>Variant ID: {variantFiltr.variant_id}</p>
                  <p>Size: {variantFiltr.variant_title}</p>
                  <p>Price: {variantFiltr.variant_price}</p>
                </div>
              ) : (
                <p>Variant not found</p>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={togglePopup}
                >
                  Close
                </button>
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="btn btn-success m-1"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
