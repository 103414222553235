import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; // Import useParams hook
import { DataGrid } from "@mui/x-data-grid"; // Updated import
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const ReturnOrderDetail = ({ authToken }) => {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTheOrder = async () => {
      setLoading(true);
      try {
        const response = await api.get(`returnorders/find/${orderId}`, {
          headers: { token: authToken },
        });
        setOrderData(response.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching order data:", error);
        setLoading(false);
      }
    };
    getTheOrder();
  }, [orderId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const columns = [
    { field: "order_id", headerName: "OID", width: 100 },
    { field: "item_name", headerName: "Product", width: 450 },
    { field: "product_id", headerName: "Product ID", width: 200 },
    { field: "quantity", headerName: "Quantity", width: 150 },
    { field: "variant_title", headerName: "Size", width: 100 },

    { field: "price", headerName: "Price", width: 150 },
    { field: "createdAt", headerName: "Time", width: 200 },
  ];

  return (
    <div>
      <div className=" d-flex justify-content-center">
        <h5 className=" alert alert-info col-6">Return Order Detail</h5>
      </div>
      <div className="d-flex shadow p-3 mb-5 bg-body rounded  justify-content-between">
        <p>Order Number: {orderId}</p>
        <p>Total Products: {orderData.total_qty}</p>
        <p>Total Price : {orderData.total_price}</p>
        <p className="">Status : {orderData.status}</p>
      </div>

      <div style={{ height: "80vh", overflowX: "auto" }}>
        <DataGrid
          rowHeight={25}
          rows={orderData.ReturnOrderDetails}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default ReturnOrderDetail;
