import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const SourceStores = ({ authToken }) => {
  const navigate = useNavigate();
  const [storesData, setStoresData] = useState([]);
  const [viewSet, setViewSet] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [formData, setFormData] = useState({
    shopName: "",
    shopifyDomain: "",
    storeApi: "",
    apiSecret: "",
    apiVersion: "",
    locationId: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const viewStores = () => {
    setViewSet((prevViewSet) => !prevViewSet);
    if (storesData.length === 0) {
      setSnackbar({
        children: "There is no store to show add  a new store",
        severity: "warning",
      });
    }
  };

  useEffect(() => {
    api
      .get("stores", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setStoresData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post(`stores/add`, formData, {
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
      });
      setFormData({
        shopName: "",
        shopifyDomain: "",
        storeApi: "",
        apiSecret: "",
        apiVersion: "",
        locationId: "",
      });
      setSnackbar({
        children: response.data.message,
        severity: "success",
      });
    } catch (error) {
      console.error("Submit Failed:", error);
      setSnackbar({ children: error.message, severity: "error" });
    }
  };

  const handleRowClick = (id) => {
    navigate(`/storesdetails/${id}`);
  };

  if (viewSet && storesData.length > 0) {
    return (
      <div className="container mt-5">
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary float-end"
          onClick={viewStores}
        >
          <i className="bi bi-gear"></i> &nbsp; Add New Store
        </button>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="d-flex  justify-content-center">
              <h4 className="fw-bold w-auto shadow-lg p-3 mb-3 rounded bg-body">
                Stores
              </h4>
            </div>
            {storesData.map((store) => (
              <div
                onClick={() => handleRowClick(store.id)}
                key={store.id}
                className=" shadow p-3 mb-3 bg-body rounded"
              >
                <div className=" d-flex flex-wrap">
                  <div className="col-sm-12 col-lg-2">
                    <h5 className=" shadow-sm p-2 bg-body rounded">
                      {store.name}
                    </h5>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-3">
                    <p>{store.domain}</p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-3">
                    <p>Status: {store.status}</p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-3">
                    <p>Date: {store.created_at}</p>
                  </div>
                  <div className="m-2 col-sm-12 col-lg-2">
                    <p
                      className={
                        !store.isActive
                          ? "btn btn-info"
                          : "shadow-sm bg-body rounded"
                      }
                    >
                      {!store.isActive ? "Not Active" : "Active"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container mt-5">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div className="d-flex justify-content-center ">
        <div className="shadow-lg p-3 mb-5 bg-body rounded col-lg-6 ">
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary float-end"
            onClick={viewStores}
          >
            <i className="bi bi-eye"></i> &nbsp; View Stores
          </button>
          <br />
          <br />
          <p>
            Please enter all information correct it can not be undo and it will
            conume your store limits
          </p>
          <h4 className="mb-5 text-center">Shopify Store Register</h4>

          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="shopName" className="form-label">
                  Name of your Shopify Registerd Store
                </label>
                <input
                  required
                  autoComplete="off"
                  className="form-control "
                  type="text"
                  placeholder="yourstore"
                  name="shopName"
                  value={formData.shopName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="shopifyDomain" className="form-label">
                  Full Sopify Domain name
                </label>

                <input
                  required
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  placeholder="yourstore.myshopify.com"
                  name="shopifyDomain"
                  value={formData.shopifyDomain}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="storeApi" className="form-label">
                  Store Api:
                </label>
                <input
                  required
                  placeholder="yourstore api"
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="storeApi"
                  value={formData.storeApi}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3 ">
                <label htmlFor="apiSecret" className="form-label">
                  {" "}
                  Api Secret:
                </label>

                <input
                  required
                  placeholder="yourstore api password"
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="apiSecret"
                  value={formData.apiSecret}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="apiVersion" className="form-label">
                  {" "}
                  Api Version:
                </label>

                <input
                  required
                  placeholder="apiVersion..."
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="apiVersion"
                  value={formData.apiVersion}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="locationId" className="form-label">
                  {" "}
                  location Id:
                </label>

                <input
                  required
                  placeholder="yourstore location inv"
                  autoComplete="off"
                  className="form-control"
                  type="text"
                  name="locationId"
                  value={formData.locationId}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <button className=" btn btn-success" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourceStores;
