import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Audio } from "react-loader-spinner";
import "./model.css";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const ReturnProductDetails = ({ authToken }) => {
  const { product_id } = useParams();
  const [productData, setProductData] = useState();
  const [variantFiltr, setVariantFilter] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [newQuantity, setNewQuantity] = useState("");
  const [loading, setLoading] = useState(true);

  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`products/find/${product_id}`, {
        headers: {
          token: authToken,
        },
      });
      setProductData(response.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching order data:", error);
      setLoading(false);
    }
    try {
      const response = await api.get("returnorders/unpaid", {
        headers: {
          token: authToken,
        },
      });
      setLoading(false);
      setOrderData(response.data);
    } catch (error) {
      console.error("Error fetching order data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [product_id]);

  const handleSubmit = () => {
    setLoading(true);

    // const qty = Number(variantFiltr.inventory_quantity);

    const order_id = orderData ? orderData.order_id : 0;

    const numericQuantity = Number(newQuantity);

    if (numericQuantity > 0) {
      api
        .post(
          "returnorders/creat",
          {
            qty: numericQuantity,
            variantId: variantFiltr.variant_id,
            itemName: productData.product_title,
            order_id: order_id,
          },
          {
            headers: {
              token: authToken,
            },
          }
        )
        .then((response) => {
          setSnackbar({
            children: response.data,
            severity: "success",
          });
          // navigate(`/returnproducts`, {
          //   state: { message: response.data.message },
          // });
          fetchData();
          setNewQuantity("");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setSnackbar({ children: error.message, severity: "error" });
        });
    } else {
      setLoading(false);
      setSnackbar({
        children: "Please Add Right Quantity Value",
        severity: "warning",
      });
    }
    setShowPopup(false);
  };

  const handleSizeClick = (variant_id) => {
    setVariantFilter(
      productData.Variants.find((variant) => variant.variant_id === variant_id)
    );
    togglePopup();
  };

  if (loading) {
    return (
      <div className="container">
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      </div>
    );
  }

  return (
    <div className="container">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

      <div className="d-flex justify-content-center">
        <div className="col-lg-4 col-xl-4 col-sm-6 shadow-lg p-3 mb-2 bg-body rounded">
          <p className="fw-semibold">Return This Product </p>
          <p className="lh-1 fs-6 text-body-warning">
            Please Read Carefully All the Size You Want To Add in Return Order
            This Process Can Not Be Undo
          </p>
          <div className="card">
            <img
              src={productData.product_image}
              className="card-img-top"
              alt="Product"
              height="450"
            />
            <div className="card-body">
              <div className="container">
                <p className="alert alert-primary row">
                  {productData.product_title}
                </p>

                <div className="row ">
                  <p className=" btn btn-success">
                    Status: {productData.product_status}
                  </p>
                </div>
              </div>

              {productData.Variants.map((variant, key) => (
                <div
                  className="btn btn-outline-secondary m-1"
                  key={variant.id}
                  onClick={() => handleSizeClick(variant.variant_id)}
                >
                  <p className="">{variant.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="">
          <div className="modals">
            <div className="modal-contents col-sm-8 col-lg-2 rounded">
              <div className=" shadow-lg p-3 mb-2 bg-body rounded">
                <div className="modal-header">
                  <h6 className="modal-title">Enter Quantity To Return </h6>
                  <span onClick={togglePopup} className="close-btn">
                    &times;
                  </span>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <input
                      placeholder="Enter Quantity"
                      type="number"
                      className="form-control m-1"
                      name="Pin"
                      value={newQuantity}
                      onChange={(e) => setNewQuantity(e.target.value)}
                    />
                  </div>
                </div>

                {variantFiltr ? (
                  <div>
                    <p>Size: {variantFiltr.title}</p>
                    <p>Price: {variantFiltr.price}</p>
                  </div>
                ) : (
                  <p>Variant not found</p>
                )}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={togglePopup}
                  >
                    Close
                  </button>
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="btn btn-success m-1"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReturnProductDetails;
