import React, { useEffect, useState } from "react";

import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Audio } from "react-loader-spinner";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SumComponent from "./SumCalculator";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const StoreDetail = ({ authToken }) => {
  const { id } = useParams();
  const [storeData, setStoreData] = useState();
  const [ordersData, setOrdersData] = useState();
  const [returnOrdersData, setReturnOrdersData] = useState();
  const [storeActive, setStoreActive] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectListChanged, setSelectListChanged] = useState(false);

  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const handelAtive = (e) => {
    setStoreActive(e.target.value);
    setSelectListChanged(!selectListChanged);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`stores/${id}`, {
          headers: {
            token: authToken,
          },
        });
        setStoreData(response.data.store);
        setOrdersData(response.data.orders);
        setReturnOrdersData(response.data.returnorders);
        setStoreActive(response.data.store.isActive);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);
        setSnackbar({
          children: "Server error no data availble",
          severity: "error",
        });
        setLoading(false);
      }
    };

    fetchData();
  }, [id, authToken]);

  const sendApprovalRequest = async () => {
    try {
      const response = await api.get(`stores/approval/${id}`, {
        headers: {
          token: authToken,
        },
      });

      setSnackbar({
        children: response.data,
        severity: "success",
      });
      setLoading(false);
    } catch (error) {
      if (error.response) {
        setSnackbar({
          children: error.response.data,
          severity: "error",
        });
      } else {
        console.error("Error fetching store data:", error);
      }
      setLoading(false);
    }
  };
  const syncproducts = async () => {
    if (window.confirm("Are You sure you want to sync")) {
      setLoading(true);
      try {
        const response = await api.get(`products/shopifysync/${id}`, {
          headers: {
            token: authToken,
          },
        });

        setSnackbar({
          children: response.data,
          severity: "success",
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);

        setLoading(false);
      }
    }
  };
  const handleSaveData = () => {
    setSnackbar({
      children: `${storeActive}-${storeData.status}`,
      severity: "success",
    });
  };
  if (loading) {
    return (
      <div className="container">
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
        />
      </div>
    );
  }
  return (
    <div className="container-fluid">
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div className="d-flex flex-wrap justify-content-evenly">
        <div className="col-lg-5 col-xl-5 col-sm-10 shadow p-3 mb-5 bg-body rounded ">
          <div className="d-flex flex-column ">
            <div className="mt-5">
              <h6 className="">{storeData.name}</h6>
            </div>

            <div className="shadow-sm p-3 mb-5 bg-body rounded ">
              <p>Store Domain: {storeData.domain}</p>
              <p>Store Api: {storeData.api_key}</p>
              <p>Store Id: {storeData.id}</p>

              <p>Date: {storeData.created_at}</p>
            </div>
            <div className="shadow-sm p-3 mb-5 bg-body rounded ">
              {storeData.status === "Approved" ? (
                <div>
                  <h5>Get New And Update Products</h5>
                  <button className="btn btn-info" onClick={syncproducts}>
                    Sync All Products
                  </button>
                </div>
              ) : (
                storeData.status
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-xl-4 col-sm-10  p-3 mb-5 ">
          <div className="shadow p-3 mb-3 bg-body rounded d-flex flex-column">
            <div>
              <p className="float-start fw-bold">Status</p>
              <p className="float-end fw-lighter">
                {storeData.status}
                <i
                  onClick={() => alert("i cliked")}
                  class="bi bi-three-dots-vertical float-end"
                ></i>
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <select
                value={storeActive}
                className="form-control w-25"
                name="storeStatus"
                id=""
                onChange={handelAtive}
              >
                <option value="false">Inactive</option>
                <option value="true">Active</option>
              </select>
              {storeData.status === "Pending" ? (
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  onClick={sendApprovalRequest}
                >
                  <i className="bi bi-gear"></i> Approval and activate
                </button>
              ) : (
                selectListChanged &&
                storeActive !== storeData.status && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => {
                      handleSaveData();
                      setSelectListChanged(!selectListChanged);
                    }}
                  >
                    <i className="bi bi-gear"></i> Save Data
                  </button>
                )
              )}
            </div>
          </div>
          <div className="shadow p-3 mb-3 bg-body rounded d-flex flex-column ">
            <div className="mb-3">
              <h6 className="float-start fw-bold">Publishing</h6>
              <i
                onClick={() => alert("i cliked")}
                className="bi bi-three-dots-vertical float-end"
              ></i>
            </div>
            <div className="d-flex flex-column align-items-start ">
              <p className="fw-medium">Slaes Channels</p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; Velvourshop
              </p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; style hub
              </p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; websnotch
              </p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; vestile wear
              </p>
              <p>
                <i className="bi bi-flower3"></i>
                &nbsp; Noirs store online and offline
              </p>
            </div>
          </div>
          <div className="shadow p-3 mb-3 bg-body rounded ">
            <div className="d-flex  flex-column">
              <div className="">
                <p className="fw-bold float-start">Insights</p>
                <p className="fw-lighter float-end">Last 90 Days</p>
              </div>
              <div>
                <p>
                  Sold <SumComponent data={ordersData} /> in net sales.
                </p>
                <p>
                  Return <SumComponent data={returnOrdersData} /> in net return.
                </p>
                <Link to={`/saledetails/${id}`} className="float-start">
                  View Details
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDetail;
