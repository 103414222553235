import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid"; // Updated import
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Audio } from "react-loader-spinner";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const InventoryForm = () => {
  const navigate = useNavigate();
  const { variantId } = useParams();

  const [productData, setProductData] = useState([]);

  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [newQuantity, setNewQuantity] = useState("");
  const fetchDataAndOrderData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`variantdata/${variantId}`);

      setProductData(response.data.data);
    } catch (error) {
      console.error("Error fetching variant data:", error.message);
    }

    try {
      const response = await api.get(`orderToday`);
      setOrderData(response.data);
    } catch (error) {
      console.error("Error fetching order data:", error.message);
    }

    setLoading(false);
  };
  useEffect(() => {
    fetchDataAndOrderData();
  }, [variantId]);

  const handleUpdateQuantity = () => {
    setLoading(true);
    const qty = productData.variant.inventory_quantity;
    const variantId = productData.variant.variant_id;
    const order_id = orderData.length > 0 ? orderData[0].orderId : "";
    const orderNumber = orderData.length > 0 ? orderData[0].id : "";
    const numericQuantity = Number(newQuantity);
    console.log(order_id);
    if (qty >= numericQuantity && numericQuantity > 0) {
      api
        .post("minusInventoryForm", {
          qty: newQuantity,
          variantId: variantId,
          order_id: order_id,
          orderNumber: orderNumber,
        })
        .then((response) => {
          setSnackbar({
            children: response.data.message,
            severity: "success",
          });
          fetchDataAndOrderData();
          setNewQuantity("");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setSnackbar({ children: error.message, severity: "error" });
        });
    } else {
      setLoading(false);
      setSnackbar({
        children: "Please Add Right Quantity Value",
        severity: "warning",
      });
    }
  };

  const handleRowClick = (params) => {
    const { row } = params;
    const { id } = row;

    navigate(`/orderdetails/${id}`);
  };
  const columns = [
    { field: "id", headerName: "Order", width: 100 },
    {
      field: "orderId",
      headerName: "orderId",
      width: 150,
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    { field: "total_price", headerName: "Total", width: 150 },
    { field: "total_qty", headerName: "Products", width: 150 },
    { field: "discount_amount", headerName: "Discount", width: 250 },
    { field: "created_at", headerName: "Date", width: 250 },
  ];

  return (
    <>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {loading ? (
        <div className="container">
          {/* Loading spinner or any loading indicator component */}
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      ) : (
        <div className="mt-2 alert alert-primary row">
          <p className="col-1">
            <img
              src={productData.product.product_image}
              alt="Product Image"
              style={{ width: 50, height: 50 }}
            />
          </p>
          <p className="col-2"> {productData.product.product_title}</p>

          <p className="col-1"> {productData.variant.variant_title}</p>
          <p className="col-1">RS: {productData.variant.variant_price}</p>
          <p className="col-1">Inv: {productData.variant.inventory_quantity}</p>

          <p className="col-1">
            <input
              className="form-control"
              type="number"
              value={newQuantity}
              onChange={(e) => setNewQuantity(e.target.value)}
            />
          </p>
          <p className="col-1 btn btn-info" onClick={handleUpdateQuantity}>
            Submit
          </p>
        </div>
      )}
      <div className="mt-2">
        <div style={{ height: 800, width: "100%" }}>
          <DataGrid
            rows={orderData}
            columns={columns}
            pageSize={5}
            onRowClick={handleRowClick}
            disableRowSelectionOnClick
          />
        </div>
      </div>
    </>
  );
};

export default InventoryForm;
