import { useEffect, useState, useContext } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, Outlet } from "react-router-dom";
import { AuthContext } from "../authContext/AuthContext";
import { logout } from "../authContext/AuthActions"; // Import logout action creator if using Redux
import { useNavigate } from "react-router-dom";
import { useRedirectIfUser } from "../hooks/UserChek";
const Dashboard = () => {
  useRedirectIfUser();
  const navigate = useNavigate();
  const { user, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    if (user) {
      setLoading(false);
    } else {
      navigate("/login");
    }
  }, [user]);

  if (loading) {
    return <p>Loading...</p>;
  }

  const isAdminUser = user && user.user.isAdmin;
  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
          <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <Link
              to=""
              className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
              <span className="fs-5 d-none d-sm-inline" onClick={handleLogout}>
                Logout
              </span>
            </Link>
            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start">
              <li>
                <Link to="/orders" className="text-white text-decoration-none">
                  <i className="fs-4 bi-cart-check-fill"></i>
                  <span className="ms-1 d-none d-sm-inline">Orders</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/orderproducts"
                  className="text-white text-decoration-none"
                >
                  <i className="fs-4 bi-backpack-fill"></i>{" "}
                  <span className="ms-1 d-none d-sm-inline">
                    Order Products
                  </span>{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/returnorders"
                  className="text-white text-decoration-none"
                >
                  <i className="fs-4 bi-save"></i>{" "}
                  <span className="ms-1 d-none d-sm-inline">Return Orders</span>{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/returnproducts"
                  className="text-white text-decoration-none"
                >
                  <i className="fs-4 bi-bootstrap-reboot"></i>
                  <span className="ms-1 d-none d-sm-inline">Add Return</span>
                </Link>
              </li>
              {isAdminUser && (
                <div>
                  <li>
                    <Link
                      to="/register"
                      className="text-white text-decoration-none"
                    >
                      <i className="fs-4 bi-person"></i>
                      <span className="ms-1 d-none d-sm-inline">Register</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/users"
                      className="text-white text-decoration-none"
                    >
                      <i className="fs-4 bi-person"></i>
                      <span className="ms-1 d-none d-sm-inline">Users</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/stores"
                      className="text-white text-decoration-none"
                    >
                      <i className="fs-4 bi-house"></i>
                      <span className="ms-1 d-none d-sm-inline">Stores</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/adminproducts"
                      className="text-white text-decoration-none"
                    >
                      <i className="fs-4 bi-grid"></i>
                      <span className="ms-1 d-none d-sm-inline">
                        Products
                      </span>{" "}
                    </Link>
                  </li>
                </div>
              )}
              <li onClick={handleLogout}>
                <i className="fs-4 bi-person"></i>
                <span className="ms-1 d-none d-sm-inline">Logout</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-sm-10 col-lg-10 col-xl-10 text-center">
          <h2 className="alert alert-success">Vnoirs</h2>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
