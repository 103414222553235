// import { useNavigate } from "react-router-dom";
// import { useEffect, useContext } from "react";
// import { useClientId } from "../components/ClientIdContext";
// import { AuthContext } from "../authContext/AuthContext";

// export function useRedirectIfUnauthorized() {
//   const navigate = useNavigate();
//   const { clientId } = useClientId();

//   useEffect(() => {
//     if (clientId === 401 || !clientId) {
//       navigate("/login", { replace: true });
//     }
//   }, [clientId]);
// }
// export function useRedirectIfUnauthorizedUser() {
//   const navigate = useNavigate();
//   const { user } = useContext(AuthContext);
//   useEffect(() => {
//     if (!user) {
//       navigate("/login", { replace: true });
//     }
//   }, [user]);
// }
import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../authContext/AuthContext";

export function useRedirectIfUser() {
  const navigate = useNavigate();
  const { user, dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    } else {
      try {
        const decodedToken = jwtDecode(user.accessToken);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          dispatch({ type: "LOGOUT" });
          navigate("/login", { replace: true });
        }
      } catch (error) {
        console.error("Error decoding JWT token:", error);
      }
    }
  }, [user, dispatch, navigate]);
}
