import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid"; // Updated import
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
const ReturnProducts = ({ authToken }) => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    api
      .get("products/active", {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoading(false); // Data has been fetched, set loading to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Error occurred, set loading to false
      });
    setSnackbar({
      children: location.state?.message || "Products Fetched",
      severity: "success",
    });
  }, [location]);

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_image", // You can use a field that represents the image URL
      headerName: "Image",
      width: 70,
      renderCell: (params) => (
        <img
          src={params.value} // Assuming params.value contains the image URL
          alt="Product Image"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_title",
      headerName: "Title",
      width: 500,
    },
    {
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      field: "product_status",
      headerName: "Status",
      width: 100,
    },
  ];

  const handleRowClick = (params) => {
    const { row } = params;
    const { product_id } = row;

    navigate(`/returnproductdetails/${product_id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h3 className="shadow p-3 mb-2 bg-body rounded">Return Products Page </h3>
      <div className="d-flex justify-content-center shadow p-3 mb-5 bg-body rounded">
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnackbar}
            autoHideDuration={6000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
        <div style={{ height: "80vh", overflowX: "auto" }}>
          <DataGrid
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "success.light",
              "& .MuiDataGrid-cell:hover": {
                color: "success.main",
              },
              "& .super-app-theme--header": {
                backgroundColor: "rgba(255, 7, 0, 0.55)",
              },
            }}
            rows={data}
            columns={columns}
            pageSize={5}
            getRowId={(row) => row.product_id}
            onRowClick={handleRowClick}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReturnProducts;
