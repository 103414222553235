import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid"; // Updated import
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const ProductsTable = ({ authToken }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [storesData, setStoresData] = useState([]);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [proId, setProId] = useState("");
  const [activeButton, setActiveButton] = useState(null);

  const getProducts = (storeId) => {
    setLoading(true);
    setActiveButton(storeId);
    api
      .get(`products/bystore/${storeId}`, {
        headers: {
          token: authToken,
        },
      })
      .then((response) => {
        const productsWithTotalQuantity = response.data.map((product) => ({
          ...product,
          totalQuantity: product.Variants.reduce(
            (acc, variant) => acc + variant.inventory_quantity,
            0
          ),
        }));
        setData(productsWithTotalQuantity);

        setLoading(false); // Data has been fetched, set loading to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Error occurred, set loading to false
      });
  };

  useEffect(() => {
    const Stores = async () => {
      try {
        const response = await api.get("stores/active", {
          headers: {
            token: authToken,
          },
        });

        setStoresData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error making request:", error);
        setSnackbar({ children: error.message, severity: "error" });
      }
    };
    Stores();
  }, []);

  const columns = [
    {
      field: "product_image", // You can use a field that represents the image URL
      headerName: "Image",
      width: 70,
      renderCell: (params) => (
        <img
          src={params.value} // Assuming params.value contains the image URL
          alt="Product Image"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    { field: "product_title", headerName: "Title", width: 500, editable: true },
    { field: "product_id", headerName: "Product ID", width: 150 },
    { field: "product_status", headerName: "Status", width: 100 },
    { field: "totalQuantity", headerName: "Inventory", width: 150 },
    { field: "created_at", headerName: "Created At", width: 250 },
  ];

  const SyncProducts = async () => {
    if (window.confirm("Are You sure you want to sync")) {
      setLoading(true);
      try {
        const response = await api.get(`products/shopifysync/${activeButton}`, {
          headers: {
            token: authToken,
          },
        });

        setSnackbar({
          children: response.data,
          severity: "success",
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);

        setLoading(false);
      }
    }
  };

  const handleRowClick = (params) => {
    const { row } = params;
    const { product_id } = row;

    navigate(`/adminproductdetails/${product_id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mt-2">
      <div className=" d-flex justify-content-center shadow-sm p-1 mb-2 bg-body rounded">
        <div className=" col-sm-12 col-lg-12  ">
          {storesData.map((store) => (
            <button
              key={store.id}
              className={`btn btn-outline-info m-1 ${
                activeButton === store.id ? "active" : ""
              }`}
              onClick={() => getProducts(store.id)}
            >
              {store.name}
            </button>
          ))}
        </div>
      </div>

      {activeButton && (
        <div className="shadow-sm p-1 mb-1 bg-body rounded">
          <button className="btn btn-outline-info" onClick={SyncProducts}>
            Sync Products of Shopify
          </button>
        </div>
      )}

      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <div style={{ height: 1000, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.product_id}
          onRowClick={handleRowClick}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProductsTable;
