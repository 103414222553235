import { useContext, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function StoreForm({ formData, handleChange, handleSubmit }) {
  const [showPassword, setShowPassword] = useState(false);
  const handleToggle = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <p>Please enter all information correctly.</p>
      <h4 className="mb-5 text-center">Register User</h4>
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="mb-3 d-flex  justify-content-between flex-column ">
            <TextField
              name="fullName"
              type={"text"}
              value={formData.fullName}
              onChange={handleChange}
              label="Ful Name"
              variant="outlined"
              size="small"
              margin="normal"
            />
            <TextField
              name="mobile"
              type={"text"}
              value={formData.mobile}
              onChange={handleChange}
              label="Mobile"
              variant="outlined"
              size="small"
              margin="normal"
            />
            <TextField
              name="username"
              type={"text"}
              value={formData.username}
              onChange={handleChange}
              label="Username"
              variant="outlined"
              size="small"
              margin="normal"
            />

            <TextField
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              label="Password"
              variant="outlined"
              size="small"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleToggle}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <TextField
              name="repassword"
              type={showPassword ? "text" : "password"}
              value={formData.repassword}
              onChange={handleChange}
              label="Re Password"
              variant="outlined"
              size="small"
              margin="normal"
            />
          </div>

          <div className="mb-3">
            <button className=" btn btn-success" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default StoreForm;
